// The idea is to have the generic basic singletons available in the web-shared-components.
// The singletons must be initialized by the main application.
// To avoid confusion, we call them here "theSharedSomeSingleton" and in the main application "theSomeSingleton".
// Other helpers that don't need initialization should work directly.
// We can put here all the helper / singletons that have no relations with a specific application,
// and can be used in common (e.g.: we cannot have here a wrapper for a state manager).
import { TranslationManager } from "../managers/translationManager";
import { BrowserDetector } from "./browser-detector/BrowserDetector";
import { Logger } from "./logger/Logger";
import A11yHelper from "./a11yHelper";
import PhoneNumberFormatHelper from "./PhoneNumberFormatHelper";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		webkitAudioContext: typeof AudioContext;
	}
}

export const defaultTooltipEnterDelay = 1000;

export const theSharedLogger = Logger.getInstance();
export const theSharedBrowser = BrowserDetector.getInstance();
export const theSharedTranslationManager = TranslationManager.getInstance();
export const theSharedA11yHelper = A11yHelper.getInstance();

export const browserIsFirefoxOrSafari = (): boolean => {
	return theSharedBrowser.isFirefox() || theSharedBrowser.isSafari();
};

/**
 * Remove sip prefix if it does exist in the contact id
 *
 * @param contactID - the contact ID to check
 * @returns - the contact id without the sip prefix
 */
export const removeSipPrefixIfExists = (contactID: string | undefined): string => {
	if (!contactID)
		return "";
	const sip = contactID.substring(0, 4);
	if (sip === "sip:")
		return contactID.substring(4, contactID.length);
	return contactID;
};

/**
 * Adjust the size of a FontAwesomeIcon depending on the type of the browser
 * @param chromeSize - the FontAwesomeIcon transform value for chrome
 * @returns a string for a FontAwesomeIcon transform value ("shrink-...") adapted to the different browsers.
 */
export const setFAIconShrinkSize = (chromeSize: number): string => {
	let shrink = "shrink-";

	if (browserIsFirefoxOrSafari()) {
		if (chromeSize === 5)
			shrink += "2";
		else
			shrink += chromeSize.toString();
	} else
		shrink += chromeSize.toString();

	return shrink;
};

/**
 * Get the phone number from a full string sip uri
 *
 * @param sipUri - the uri to check
 * @returns - the phone number only
 */
export const getPhoneNumberFromSIPURI = (sipUri?: string): string => {
	if (sipUri === undefined)
		return "";
	let phone = removeSipPrefixIfExists(sipUri);
	const split = phone.split("@");
	if (split.length)
		phone = split[0];
	return phone;
};

/**
 * Check if a certain string is a phone number
 * We first strip out the potential SIP formatting
 * Then we use the library google-libphonenumber to check if it's a valid phone number
 * @param searched - the string to test
 * @returns - true if it's a phone number, false otherwise
 */
export const isAValidPhoneNumber = (searched?: string): boolean => {
	if (searched === undefined)
		return false;
	const phoneNumber = PhoneNumberFormatHelper.getPhoneNumber(getPhoneNumberFromSIPURI(searched));
	return phoneNumber.isValid;
};

/**
 * Check if a certain string is a potential phone number
 * It can contain only numbers and the "+"
 *
 * @param searched - the string to test
 * @returns - true if it's a potential phone number, false otherwise
 */
export const isAPotentialPhoneNumber = (searched?: string): boolean => {
	if (searched === undefined)
		return false;
	searched = searched.replaceAll(" ", "");
	return getPhoneNumberFromSIPURI(searched).search(/^[+\d]*\d{2}[+\d]*$/) >= 0;
};

/**
 * Add the sip prefix if it does not exist in the contact id
 *
 * @param contactID - the contact ID to check
 * @returns - the correct sip
 */
export const addSipPrefixIfNotExists = (contactID: string): string => {
	const sip = contactID.substring(0, 4);
	if (sip === "sip:")
		return contactID;
	return "sip:" + contactID;
};
