import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

export interface IPhoneNumberFormat {
	rawInput: string;
	isUnknown?: boolean; // true if the rawInput is an empty string
	E164?: string;
	RFC3966?: string;
	originalFormat?: string;
	toDial: string; // best format for dialer
	toDisplay: string; // best format to show in the UI
	toSearch: string; // best format to use for searches in databases (both client and server)
	isValid: boolean;
	isPossible: boolean;
	region?: string; // Country code in 2-digit format (DE, IT, ES)
}

/**
 * Helper class using google.libphonenumber
 * to validate and get formatted phone number
 */
export default class PhoneNumberFormatHelper {
	public static phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
	/**
	 * Get phone number in IPhoneNumberFormat
	 * @param rawInput - the input phone number
	 * @param regionCode - optional region code
	 * @returns - IPhoneNumberFormat
	 */
	public static getPhoneNumber(rawInput: string, regionCode?: string): IPhoneNumberFormat {
		// Do not try to validate an empty string
		// return the interface anyway
		if (!rawInput) {
			return {
				rawInput,
				isUnknown: true,
				originalFormat: rawInput,
				toDial: rawInput,
				toDisplay: rawInput,
				toSearch: rawInput,
				isValid: false,
				isPossible: false
			};
		}
		let phoneNumber: PhoneNumber | undefined;
		let isValid = false;
		let isPossible = false;
		let originalFormat = rawInput;
		// default to input string, if it cannot be validated as phone number, the input is used as it is for dialing
		let toDial = rawInput;
		// default to input string, if it cannot be validated as phone number, the input is used as it is for displaying
		let toDisplay = rawInput;
		// default to input string, if it cannot be validated as phone number, the input is used as it is for searching
		let toSearch = rawInput;
		let E164;
		let RFC3966;
		let region;

		// Try parsing international number format
		try {
			phoneNumber = PhoneNumberFormatHelper.phoneNumberUtil.parseAndKeepRawInput(rawInput);
		} catch (e) {

		}

		// Try parsing number with a region code
		if (!phoneNumber && regionCode) {
			try {
				phoneNumber = PhoneNumberFormatHelper.phoneNumberUtil.parseAndKeepRawInput(rawInput, regionCode);
			} catch (e) {

			}
		}

		if (phoneNumber) {
			isValid = PhoneNumberFormatHelper.phoneNumberUtil.isValidNumber(phoneNumber);
			isPossible = PhoneNumberFormatHelper.phoneNumberUtil.isPossibleNumber(phoneNumber);
			if (isPossible) {
				originalFormat = PhoneNumberFormatHelper.phoneNumberUtil.formatInOriginalFormat(phoneNumber);
				// the library is not able to recognize local city numbers, of course
				// for this reason we have to manually remove the unwanted chars instead of using the formatted number
				toDial = PhoneNumberFormatHelper.removeUnwantedCharacters(originalFormat);
				toDisplay = originalFormat;
				toSearch = PhoneNumberFormatHelper.removeLeadingZeros(toDial);
				E164 = PhoneNumberFormatHelper.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164);
				RFC3966 = PhoneNumberFormatHelper.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.RFC3966);
				region = PhoneNumberFormatHelper.phoneNumberUtil.getRegionCodeForNumber(phoneNumber);
			}
		}

		return {
			rawInput,
			originalFormat,
			toDial,
			toDisplay,
			toSearch,
			E164,
			RFC3966,
			isValid,
			isPossible,
			region
		};
	}

	/**
	 * Remove leading zeros from a potential phone number (in string format)
	 * this will remove any leading zero
	 * e.g.: 000001234 will become 1234, 10001234 will not change
	 * @param value - the value to process
	 * @returns - the cleaned value
	 */
	public static removeLeadingZeros(value: string): string {
		let cleaned = value;
		for (let char = 0; char < value.length; char++) {
			// break at the first non "0" character
			if (value[char] !== "0") {
				cleaned = value.slice(char, value.length);
				break;
			}
		}
		return cleaned;
	}

	/**
	 * Remove all unwanted characters from a potential phone number written in an input field
	 * with characters like ( ) / - and (0)
	 * @param value - the value to clean
	 * @returns - the cleaned value
	 */
	public static removeUnwantedCharacters(value: string): string {
		return value.replaceAll(/\(0\)|[ ()/-]/gm, "");
	}
}
