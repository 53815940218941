import Bowser from "bowser"; // https://github.com/lancedikson/bowser
import adapter from "webrtc-adapter";
import type { IAdapter } from "webrtc-adapter";
import {
	IBrowser,
	IBrowserDetails,
	IBrowserIs,
	IBrowserOs,
	IBrowserSupports,
	IHTMLAudioWithSinkID
} from "./IBrowserDetector";
import { ILogData, ILogger } from "../logger/ILogger";
export const navigator_media: Navigator = navigator as Navigator;

export interface INavigator extends Navigator {
	userAgentData?: { platform: string };
	mediaDevices: IMediaDevices;
}

interface IMediaDevices extends MediaDevices{
	selectAudioOutput?: () => Promise<MediaDeviceInfo>;
}

/**
 * Helper for the browser detection.
 * Usage examples:
 * import theBrowser from globals
 * if (theBrowser.supports.dtx) doSomething()
 * if (theBrowser.is.safariIOS) doSomething()
 */
export class BrowserDetector implements IBrowser {
	// Instance of this class to use as singleton.
	private static instance: BrowserDetector;
	private logger: ILogger | undefined;

	// Interface IBrowser.
	public readonly is: IBrowserIs;
	public readonly os: IBrowserOs;
	public readonly name: string;
	public readonly supports: IBrowserSupports;
	public readonly type: string;
	public version: string;
	public readonly engine: string;

	// The webRTCAdapter must be loaded for the polyfills needed in some browsers.
	// We are not using its methods to detect the browser in this class, we are just importing it for the browsers to use.
	public readonly webRTCAdapter: IAdapter;

	// "Bowser" parser.
	private readonly parser: Bowser.Parser.Parser;

	/**
	 * Constructs BrowserDetector. Assigning the "Bowser" parser to a class attribute to easily access later.
	 *
	 */
	private constructor() {
		this.parser = Bowser.getParser(window.navigator.userAgent);
		this.logger = undefined;
		this.webRTCAdapter = adapter;
		this.name = this.parser.getBrowserName(true) || "";
		this.type = this.parser.getPlatformType();
		this.version = this.parser.getBrowserVersion();
		this.engine = this.parser.getEngine().name?.toLowerCase() || "";
		this.os = {
			name: this.parser.getOSName(true),
			version: this.parser.getBrowserVersion(),
			versionName: this.parser.getOSVersion()
		};
		this.is = {
			// This parameter is needed for showing a different layout to the mobile browsers.
			// This is not applying to tablets.
			mobile: this.parser.getPlatformType() === "mobile",
			tablet: this.parser.getPlatformType() === "tablet",
			// Knowing that is Safari running in a iOS or iPadOS is needed for a particular case:
			// we cannot request getUserMedia more than once if the streaming is already in page.
			safariIOS: this.isSafariIOS(),
			supported: this.isBrowserSupported(),
			touchScreen: this.isTouchDevice(),
			macintosh: this.isMacintosh()
		};
		this.supports = {
			dtx: this.supportsDTX(),
			unifiedPlan: this.supportsUnifiedPlan(),
			vp8: true,
			vp9: this.isVp9supported(),
			webRTC: BrowserDetector.isWebRTCSupported(),
			mediaTrackSupportedConstraints: navigator?.mediaDevices?.getSupportedConstraints(),
			indexedDB: undefined,
			sinkId: undefined,
			selectAudioOutput: undefined,
			notifications: "Notification" in window
		};
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "BrowserDetector",
			classProps: {
				is: this.is,
				name: this.name,
				supports: this.supports,
				type: this.type,
				version: this.version
			}
		};
	}

	/**
	 * Gets instance of BrowserDetector to use as singleton.
	 *
	 * @returns - an instance of this class.
	 */
	public static getInstance(): BrowserDetector {
		if (!BrowserDetector.instance)
			BrowserDetector.instance = new BrowserDetector();
		return BrowserDetector.instance;
	}

	/**
	 * Detects the browser and makes the assignments to the class attributes.
	 */
	public async init(): Promise<void> {
		const version = await this.getUAVersionFromHintsAPI();
		if (version)
			this.version = version;
		try {
			this.supports.indexedDB = await this.isIndexedDBAvail();
		} catch (e) {
			this.supports.indexedDB = false;
		}
		try {
			this.supports.vp8 = await this.isVp8supported();
		} catch (e) {
			if (this.logger)
				this.logger.error("Error initializing theBrowser", "init", this, { e });
			this.supports.vp8 = false;
		}
		this.checkSpeakerAndSinkId();
		if (this.logger)
			this.logger.info("Browser detected", "init", this);
	}

	/**
	 * Detect browser version adapted to the User-Agent Client Hints API
	 * https://web.dev/articles/migrate-to-ua-ch?hl=en
	 * @returns - a string in promise
	 */
	private async getUAVersionFromHintsAPI(): Promise<string | undefined> {
		try {
			// FIXME: Types are not yet fully available, adapt it when they are
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			if (window.navigator.userAgentData) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
				const moreData = await navigator.userAgentData.getHighEntropyValues(["uaFullVersion"]);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				return moreData.uaFullVersion as string;
			} else
				return undefined;
		} catch (e) {
			return undefined;
		}
	}

	/**
	 * Set the logger
	 *
	 * @param logger - the logger interface
	 */
	public setLogger(logger: ILogger) {
		this.logger = logger;
	}

	/**
	 * Checks if we this browser is chrome
	 *
	 * @returns - true in case we are runnning in chrome
	 */
	public isChrome(): boolean {
		return this.name === "chrome";
	}

	/**
	 * Checks if we this browser is chromium based (chrome, microsoft edge, opera)
	 *
	 * @returns - true in case we are runnning in a chromium based browser
	 */
	public isChromium(): boolean {
		return this.engine === "blink";
	}

	/**
	 * Checks if we this browser is edge
	 *
	 * @returns - true in case we are runnning in edge
	 */
	public isEdge(): boolean {
		return this.name === "microsoft edge";
	}

	/**
	 * Checks if we this browser is firefox
	 *
	 * @returns - true in case we are runnning in firefox
	 */
	public isFirefox(): boolean {
		return this.name === "firefox";
	}

	/**
	 * Checks if we this browser is Safari
	 *
	 * @returns - true in case we are runnning in Safari
	 */
	public isSafari(): boolean {
		return this.name === "safari";
	}

	/**
	 * Retrieve the IBrowserDetails for logging and diagnostic
	 *
	 * @returns - IBrowserDetails
	 */
	public getIBrowser(): IBrowserDetails {
		return {
			is: this.is,
			os: this.os,
			name: this.name,
			supports: this.supports,
			type: this.type,
			version: this.version,
			engine: this.engine,
			isChrome: this.isChrome(),
			isChromium: this.isChromium(),
			isEdge: this.isEdge(),
			isFirefox: this.isFirefox(),
			isSafari: this.isSafari()
		};
	}

	/**
	 * Checks if we support the browser, using Bowser library.
	 *
	 * @returns - true in case we support the browser, false otherwise.
	 */
	private isBrowserSupported(): boolean {
		// This list is considering the support for webrtc:
		// https://caniuse.com/#search=webrtc
		// We support Safari only starting from 12.1.
		// Without specifying the OS the item is including both desktop and mobile.
		const satisfies = this.parser.satisfies({
			chrome: ">=56",
			safari: ">=12.1",
			firefox: ">=44",
			edge: ">=18", // the chromium based is also returning edge here.
			opera: ">=43", // Chromium 56
			samsung_internet: ">=11.2"
		});

		return satisfies !== undefined ? satisfies : false;
	}

	/**
	 * Checks whether the browser supports webrtc or not.
	 *
	 * @returns - true in case it supports webrtc or false in case it does not.
	 */
	private static isWebRTCSupported(): boolean {
		return window.RTCPeerConnection != null &&
			navigator_media.mediaDevices != null &&
			navigator_media.mediaDevices.getUserMedia != null;
	}

	/**
	 * Checks whether unified plan is supported by the browser or not.
	 *
	 * @returns - true in case unified plan is supported or false if not.
	 */
	private supportsUnifiedPlan(): boolean {
		// Since this method is creating a new RTCPeerConnection, we need to check
		// if the browser is supporting WebRTC, otherwise we don't execute it.
		if (!BrowserDetector.isWebRTCSupported())
			return false;

		let supportsUnifiedPlan: boolean;

		if (this.parser.satisfies({
			chrome: ">=72",
			firefox: ">=59",
			edge: ">=79", // first Chromium version
			opera: ">=60" // Chromium 73 (72 not released)
		}))
			supportsUnifiedPlan = true;
		else if (this.parser.isBrowser("safari", true) &&
			window.RTCRtpTransceiver && ("currentDirection" in RTCRtpTransceiver.prototype))
			supportsUnifiedPlan = true;
		else {
			supportsUnifiedPlan = false;
			const pc = new RTCPeerConnection();
			if (pc) {
				try {
					if (pc.addTransceiver) {
						try {
							pc.addTransceiver("audio");
							supportsUnifiedPlan = true;
						} catch (error) {
							if (this.logger)
								this.logger.error("Error adding Transceiver", "supportsUnifiedPlan", this, { error });
							supportsUnifiedPlan = false;
						}
					}
				} catch (error) {
					if (this.logger)
						this.logger.error("Error adding Transceiver", "supportsUnifiedPlan", this, { error });
				}
				pc.close();
			}
		}
		return supportsUnifiedPlan;
	}

	/**
	 * Are we supporting DTX silence suppression
	 * Chromium embedded do it (edge, opera, chrome)
	 * Ff is working on it - https://bugzilla.mozilla.org/show_bug.cgi?id=1418804
	 * Safari is currently unknown
	 *
	 * @returns - true in case we support DTX, false if not.
	 */
	private supportsDTX(): boolean {
		return this.parser.satisfies({
			chrome: ">=56",
			safari: ">=12.1",
			// firefox: ">=78",			Due to audio issues we currently disable DTX for firefox
			edge: ">=79", // first Chromium version
			opera: ">=43" // Chromium 56
		}) || false;
	}

	/**
	 * Detects if vp8 is supported.
	 *
	 * @returns - true in case vp8 is supported, false otherwise.
	 */
	private async isVp8supported(): Promise<boolean> {
		// Since this method is creating a new RTCPeerConnection, we need to check
		// if the browser is supporting WebRTC, otherwise we don't execute it.
		if (!BrowserDetector.isWebRTCSupported())
			return false;

		if (!this.parser.isBrowser("safari", true))
			return true;
		else {
			let isVp8supported = false;
			if (this.parser.satisfies({ safari: ">=12.1" }) && RTCRtpSender && RTCRtpSender.getCapabilities) {
				// Let's see if RTCRtpSender.getCapabilities() is there
				const videocaps = RTCRtpSender.getCapabilities("video");
				if (videocaps && videocaps.codecs && videocaps.codecs.length) {
					for (const codec of videocaps.codecs) {
						if (codec.mimeType && codec.mimeType.toLowerCase() === "video/vp8") {
							isVp8supported = true;
							break;
						}
					}
				} else {
					// We do it in a very ugly way, as there's no alternative...
					// We create a PeerConnection to see if VP8 is in an offer
					const pc = new RTCPeerConnection({});

					try {
						const offer = await pc.createOffer({ offerToReceiveVideo: true });
						if (offer != null && offer.sdp != null)
							isVp8supported = offer.sdp.indexOf("VP8") !== -1;
						pc.close();
					} catch (error) {
						if (this.logger)
							this.logger.error("Error during createOffer", "isVp8supported", this, { error });
					}
				}
			}
			return isVp8supported;
		}
	}

	/**
	 * Detects if vp9 is supported.
	 *
	 * @returns - true in case vp9 is supported, false otherwise.
	 */
	private isVp9supported(): boolean {
		return !this.parser.isBrowser("safari", true) && BrowserDetector.isWebRTCSupported();
	}

	/**
	 * Detects if this is Safari browser on a IOS or IpadOS.
	 * This method is also using this.iOSLying to check if we are in an iPad that pretends to be a MacOS.
	 *
	 * @returns - true in case the browser is Safari on iOS, false otherwise.
	 */
	private isSafariIOS(): boolean {
		const satisfies = this.parser.satisfies({
			mobile: {
				safari: ">=0"
			},
			tablet: {
				safari: ">=0"
			}
		});
		let platFormInNavigator;
		if (navigator.platform)
			platFormInNavigator = navigator.platform;
		// eslint-disable-next-line compat/compat
		const iPadORiPhone = platFormInNavigator !== undefined && navigator.maxTouchPoints > 1 &&
			(platFormInNavigator === "MacIntel" || platFormInNavigator === "iPad" || platFormInNavigator === "iPhone");
		return (satisfies !== undefined && satisfies) || iPadORiPhone;
	}

	/**
	 * Detects if indexedDB is available.
	 *
	 * @returns - a promise
	 */
	private async isIndexedDBAvail(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (typeof indexedDB === "undefined")
				reject(new Error("not supported"));
			const db = indexedDB.open("inPrivate");
			db.onsuccess = () => {
				indexedDB.deleteDatabase("inPrivate");
				resolve(true);
			};
			db.onerror = () => {
				indexedDB.deleteDatabase("inPrivate");
				reject(new Error("not supported"));
			};
		});
	}

	/**
	 * Detects if sinkID is available.
	 *
	 */
	private checkSpeakerAndSinkId(): void {
		this.supports.selectAudioOutput = typeof (navigator as INavigator)?.mediaDevices.selectAudioOutput === "function";
		const audio = document.createElement("audio") as IHTMLAudioWithSinkID;
		this.supports.sinkId = audio && typeof audio.setSinkId === "function";
		audio.remove();
	}

	/**
	 * Detects if it's a touch device
	 *
	 * @returns - true if is a touch
	 */
	private isTouchDevice(): boolean {
		// eslint-disable-next-line compat/compat
		return (("ontouchstart" in window) || (navigator.maxTouchPoints > 0));
	}

	/**
	 * Helper to get the platform name
	 *
	 * @returns - the platform name
	 */
	private getPlatform() {
		// 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
		if (typeof (navigator satisfies INavigator as INavigator).userAgentData !== "undefined" && (navigator satisfies INavigator as INavigator).userAgentData != null)
			return (navigator satisfies INavigator as INavigator).userAgentData?.platform || "unknown";

		// Deprecated but still works for most of the browser
		if (typeof navigator.platform !== "undefined") {
			if (typeof navigator.userAgent !== "undefined" && /android/.test(navigator.userAgent.toLowerCase())) {
				// android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
				return "android";
			}
			return navigator.platform;
		}
		return "unknown";
	}

	/**
	 * Detects if it's a apple macintosh
	 *
	 * @returns - true if is a macintosh
	 */
	public isMacintosh(): boolean {
		// eslint-disable-next-line compat/compat

		const platform = this.getPlatform();

		// examples of use
		const isOSX = /mac/.test(platform.toLowerCase()); // Mac desktop
		const isIOS = ["iphone", "ipad", "ipod"].indexOf(platform.toLowerCase()) !== -1; // Mac iOs
		const isApple = isOSX || isIOS; // Apple device (desktop or iOS)

		return isApple;
	}
}
