import {
	AsnPresenceStateEnum,
	AsnSetClientIdleStateArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface";
import { ROSEError, ROSEReject } from "../../web-shared-components/asn1/EUCSrv/stubs/SNACCROSE";
import { ILogCallback, ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";
import { SocketTransport } from "../session/SocketTransport";
import { IIdleProfile } from "../zustand/myselfSlice";
import { getState } from "../zustand/store";

/**
 * This Manager takes care about the operations related to the logged-in user.
 */
export class MeManager extends BaseSingleton implements ILogCallback {
	// The singleton instance of this class
	private static instance: MeManager;
	private socket: SocketTransport;

	/**
	 * Constructs MeManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);
		this.socket = SocketTransport.getInstance();
	}

	/**
	 * Gets instance of MeManager to use as singleton.
	 * @param attributes - the constructor attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): MeManager {
		if (!MeManager.instance) MeManager.instance = new MeManager(attributes);
		return MeManager.instance;
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "MeManager"
		};
	}

	/**
	 * initialize Redux with some Profile-Settings
	 * - Idle Settings
	 * - CustomTabs (coming soon)
	 * @returns - failure/success
	 */
	public async init() {
		const domProfile = await this.getServerBasedClientSettings();
		if (!(domProfile instanceof Document)) return false;
		// using: [Introduction to using XPath in JavaScript](https://developer.mozilla.org/en-US/docs/Web/XPath/Introduction_to_using_XPath_in_JavaScript)
		const ROOT = "/ServerBasedClientSettings";
		const PATH = "/Software/Policies/ESTOS/UCServer4/CtiMain";

		// const XPATHPROFILENAME = `${ROOT}/HKEY_CURRENT_USER${PATH}/SwProfileName/@value`;

		/*
		 * these defaults MUST match the: "NOT Cofigured" state from ADM.
		 * (means the Profile does not contain the key/values)
		 * compare/see also: src\redux\reducers\mySelf\mySelfSlice.ts, initialState
		 */
		const idleSettings: IIdleProfile = {
			idleEnabled: true,
			idleDelay: 5,
			idleSessionLock: true
		};
		const resIdleEnabled = domProfile.evaluate(
			`${ROOT}/HKEY_CURRENT_USER${PATH}/Idle_x0020_Enabled/@value`,
			domProfile,
			null,
			XPathResult.NUMBER_TYPE
		);

		idleSettings.idleEnabled = resIdleEnabled.numberValue !== 0; // NaN leads to true
		if (idleSettings.idleEnabled) {
			// query for Delay ONLY if Enabled
			// keep synchronized with: estos\etapimonitor\ECtiMainSettingsDefines.h
			const resIdleDelay = domProfile.evaluate(
				`${ROOT}/HKEY_CURRENT_USER${PATH}/Idle_x0020_Delay/@value`,
				domProfile,
				null,
				XPathResult.STRING_TYPE
			);
			switch (resIdleDelay.stringValue) {
				case "DEB2D0BF-E446-42df-B1D9-FA969FD9D488": // 1 Min.
					idleSettings.idleDelay = 1;
					break;
				case "DFC9FA12-B809-4f7d-8C40-FC1F788F964C": // EGUID_EVENT_IDLE2
					idleSettings.idleDelay = 2;
					break;
				case "B5B28C61-1D51-4a2f-B813-658EDC7EC2E7": // EGUID_EVENT_IDLE3
					idleSettings.idleDelay = 3;
					break;
				case "1BE4046F-10EC-4ded-BC78-27552004B46D": // EGUID_EVENT_IDLE4
					idleSettings.idleDelay = 4;
					break;
				case "0AE04A1A-FA7D-45f1-9194-61020FEAB579": // EGUID_EVENT_IDLE10
					idleSettings.idleDelay = 10;
					break;
				case "23E2EDE8-C353-423e-B504-4A459D18F859": // EGUID_EVENT_IDLE15
					idleSettings.idleDelay = 15;
					break;
				case "5F861648-B845-49c7-8677-20C665657C5F": // EGUID_EVENT_IDLE20
					idleSettings.idleDelay = 20;
					break;
				case "BCD86EE1-ED9B-41be-A86C-AD1C646462E6": // EGUID_EVENT_IDLE25
					idleSettings.idleDelay = 25;
					break;
				case "1D773F4B-64C5-4d9d-8C0C-3F8D77D0F555": // EGUID_EVENT_IDLE30
					idleSettings.idleDelay = 30;
					break;
				case "311BBE68-4D62-4d2a-AB9C-3AFB3EDC3D02": // EGUID_EVENT_IDLE5
				default:
					idleSettings.idleDelay = 5;
					break;
			}
		} else idleSettings.idleDelay = 5;
		const resIdleSesionLock = domProfile.evaluate(
			`${ROOT}/HKEY_CURRENT_USER${PATH}/Idle_x0020_SessionLock/@value`,
			domProfile,
			null,
			XPathResult.NUMBER_TYPE
		);
		idleSettings.idleSessionLock = resIdleSesionLock.numberValue !== 0;
		getState().setMyselfIdleSettings(idleSettings);
		return true;
	}

	/**
	 * getServerBasedClientSettings(V1) DEPRECATED
	 * @returns - Document or EError
	 */
	public async getServerBasedClientSettings(): Promise<Document | Error> {
		const argument = { _type: "AsnGetServerBasedClientSettingsArgument" };
		return new Promise((resolve) => {
			const callBack = (result: unknown) => {
				if (result instanceof ROSEError || result instanceof ROSEReject) {
					this.logger.error("Error getServerBasedClientSettings", "getServerBasedClientSettings", this, { result });
					resolve(new Error("Error getServerBasedClientSettings"));
				} else {
					const domParser = new DOMParser();
					// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
					const resGetServerBasedClientSettings: any = result as any;
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					const sSettingsData: string = resGetServerBasedClientSettings.clientSettings.osSettingsData as string;

					const xmlRoot = domParser.parseFromString(sSettingsData, "application/xml");
					resolve(xmlRoot);
				}
			};
			this.socket.send("asnGetServerBasedClientSettings", argument, callBack);
		});
	}

	/**
	 * Set the idle state
	 * @param iIdleState - the state
	 */
	public setIdleState(iIdleState: AsnPresenceStateEnum) {
		const u8sContactId = getState().ownContactId;
		if (!u8sContactId) {
			this.logger.debug("Contact id not found", "setIdleState", this, {
				u8sContactId
			});
			return;
		}
		const argument = new AsnSetClientIdleStateArgument({ u8sContactId, iIdleState });
		this.socket.send("asnSetClientIdleState", argument);
	}
}
