import { t } from "i18next";

import { IMediaDevice } from "../../web-shared-components/react-components/calls/interfaces/IMediaDevice";
import { Eav_CallType } from "../interfaces/IAVCall";
import { getState } from "../zustand/store";

/**
 * WebRTC helper class
 */
export default class WebRTCHelper {
	/**
	 * Get a readable av call type
	 * @param callType - the call type to test
	 * @returns - a readable string
	 */
	public static getAVCallReadableType(callType: Eav_CallType): string {
		switch (callType) {
			case Eav_CallType.EAV_CALLTYPE_AUDIOVIDEO:
				return t("IDS_CALL_VIDEO");
			case Eav_CallType.EAV_CALLTYPE_AUDIO:
				return t("IDS_CALL_AUDIO");
			default:
				return "";
		}
	}

	/**
	 * Removes the devices whose ID is "default" or "communications"
	 * this is only happening in Chrome and results in double listing of a device
	 * @param data - the device list
	 * @returns - the new device list
	 */
	public static removeDefaultDeviceIds(data: IMediaDevice[]): IMediaDevice[] {
		const newData: IMediaDevice[] = [];
		for (const dev of data) {
			const device: IMediaDevice = {
				deviceId: dev.deviceId,
				groupId: dev.groupId,
				label: dev.label,
				kind: dev.kind
			};
			if (dev.deviceId !== "default" && dev.deviceId !== "communications") newData.push(device);
		}
		return newData;
	}

	/**
	 * Gets the device id from the same device group and kind.
	 * @param groupId - the device group id
	 * @param data - the device list
	 * @returns - the device id or undefined if nothing has been found
	 */
	public static getDeviceIdFromSameGroup(groupId: string | undefined, data: IMediaDevice[]): string | undefined {
		const item = data.filter((i: IMediaDevice) => i.groupId === groupId);
		let deviceId;
		if (item && item.length) deviceId = item[0]?.deviceId;

		return deviceId;
	}

	/**
	 * Get media constraints
	 * @param audioOnly - if true, no video constraints
	 * @returns - the media constraints
	 */
	public static getMediaConstraints(audioOnly?: true): MediaStreamConstraints {
		const selectedMic = getState().selectedMic;
		const selectedCam = getState().selectedCam;

		const audio: MediaTrackConstraints = {
			echoCancellation: true
		};
		if (selectedMic) audio.deviceId = selectedMic;

		let video: MediaTrackConstraints | undefined = {
			width: { ideal: 1280, max: 1920 },
			height: { ideal: 720, max: 1080 },
			aspectRatio: { ideal: 1.7777777778 }
		};
		if (selectedCam) video.deviceId = selectedCam;

		if (audioOnly) video = undefined;

		return {
			audio,
			video
		};
	}
}
