import { cloneDeep } from "lodash";

import { AsnNetDatabaseContact, AsnOptionalParam, AsnStringPairList } from "../../../asn1/EConf/stubs/ENetUC_Common";
import { AsnClientPersistenceEventArgument } from "../../../asn1/EUCSrv/stubs/ENetUC_ClientPersistence";
import { theSharedLogger } from "../../../helpers/helpers";
import { IKeyValuePair } from "../../../interfaces/IKeyValuePair";
import {
	DetailTypeE,
	FunctionTypeE,
	IConfigBlock,
	IConfigCW,
	IConfigU8sDetail,
	IFunctionality,
	StyleTypeE,
	SubTypeE
} from "../../../interfaces/interfacesConfig";
import { configCDInitialData } from "../models/InitialConfigCDData";
import { configCWInitialData } from "../models/InitialConfigCWData";
import { customLabelsInitialData } from "../models/InitialCustomLabelsData";
import { EmptyStatusInfos, IContentDetails, IContentPic, ICustomerLabels } from "../models/ModelContentData";
import { IModelJsonConfigCD, IModelJsonConfigCW, IModelJsonDetails } from "../models/ModelJsonConfig";
import {
	IPermittedDetail,
	PermittedDetailNamesMap,
	PermittedDetailsMap,
	U8sAllPermittedDetails
} from "../models/ModelU8sPermittedDetails";
import PhoneNumberFormatHelper from "../../../helpers/PhoneNumberFormatHelper";

export const jsonToFunctionalities = (jsonFuncts: IFunctionality[]): IFunctionality[] => {
	const functionalities: IFunctionality[] = [];

	if (
		!jsonFuncts ||
		!jsonFuncts.length ||
		jsonFuncts.length === 0 ||
		(jsonFuncts.length === 1 && !jsonFuncts[0].funct)
	) {
		for (const funct in FunctionTypeE) {
			if (!funct)
				break;

			const basicFunct = {
				funct: funct as FunctionTypeE,
				visible: false,
				title: null
			};
			functionalities.push(basicFunct);
		}
	} else {
		for (const fct of jsonFuncts) {
			if (!fct)
				break;

			const funct: FunctionTypeE | null =
				fct.funct && Object.values(FunctionTypeE).find((type) => type === fct.funct) ? fct.funct : null;
			const title = fct.title ? fct.title : null;
			const visible = funct && fct.visible && fct.visible === true ? true : false;

			functionalities.push({ funct, visible, title });
		}
	}
	return functionalities;
};

/**
 * Proccesses a config JSON data (= configuration data with customer modifications) to an array of IConfigBlocks.
 * This is a security check for each detail in a json configuration file.
 * @param confJson - JSON data to construct an configuration ( = array of IConfigBlock)
 * @returns an array of ConfigBlocks
 */
export const jsonDetailsToBlocks = (confJson: IModelJsonDetails): IConfigBlock[] => {
	if (!confJson)
		return [];

	const namesChecklist: Map<string, boolean> = PermittedDetailNamesMap();
	const blocksToValidate: IConfigBlock[] = confJson.u8sContactDetails.concat(
		confJson.customFields,
		confJson.contactURLs
	);
	const validatedBlocks: IConfigBlock[] = [];

	const validateStyles = (detail: IConfigU8sDetail): StyleTypeE[] | null => {
		if (!detail || !detail.style || !detail.style.length || detail.style.length === 0)
			return null;

		const entryStyles: StyleTypeE[] = detail.style;
		const allStyles: boolean = detail.type === DetailTypeE.TXT || detail.type === DetailTypeE.ADR;
		const stylesToReturn: StyleTypeE[] = [];

		if (entryStyles.indexOf(StyleTypeE.BLD) > -1)
			stylesToReturn.push(StyleTypeE.BLD);

		if (entryStyles.indexOf(StyleTypeE.HGH) > -1)
			stylesToReturn.push(StyleTypeE.HGH);

		if (allStyles && entryStyles.indexOf(StyleTypeE.RED) > -1)
			stylesToReturn.push(StyleTypeE.RED);

		if (allStyles && entryStyles.indexOf(StyleTypeE.GRN) > -1 && stylesToReturn.indexOf(StyleTypeE.RED) === -1)
			stylesToReturn.push(StyleTypeE.GRN);

		return stylesToReturn;
	};

	const validateSubType = (detail: IConfigU8sDetail): SubTypeE | null => {
		const cfSubTypeOk: boolean =
			detail.subtype === SubTypeE.EML || detail.subtype === SubTypeE.FON || detail.subtype === SubTypeE.URL;

		if (detail.type === DetailTypeE.CFDTL)
			return cfSubTypeOk ? detail.subtype : SubTypeE.TXT;
		else if (detail.type === DetailTypeE.CUDTL)
			return detail.subtype === SubTypeE.APP ? SubTypeE.APP : SubTypeE.LNK;
		else
			return null;
	};

	if (blocksToValidate) {
		for (const block of blocksToValidate) {
			if (!block || !block.name)
				break;

			const blockDetailsToSet: IConfigU8sDetail[] = [];

			if (block.details) {
				for (const blockDetail of block.details) {
					if (
						!blockDetail ||
						!blockDetail.name ||
						!namesChecklist.get(blockDetail.name) ||
						(block.type === DetailTypeE.CUBLK && blockDetail.type !== DetailTypeE.CUDTL)
					)
						break;

					const addrDetailsToSet: IConfigU8sDetail[] = [];

					if (blockDetail.type === DetailTypeE.ADR && blockDetail.details) {
						for (const addr of blockDetail.details) {
							if (!addr || !addr.name)
								break;

							const addrDetailToSet: IConfigU8sDetail = {
								id: "",
								name: addr.name,
								type: Object.values(DetailTypeE).find((type) => type === addr.type) ? addr.type : DetailTypeE.EMPTY,
								subtype: validateSubType(addr),
								local: addr.local ? addr.local : "",
								title: addr.title ? addr.title : "",
								visible: addr.visible && addr.visible === true ? true : false,
								style: validateStyles(addr),
								details: addr.details
							};

							addrDetailsToSet.push(addrDetailToSet);
						}
					}

					const blockDetailToSet: IConfigU8sDetail = {
						id: "",
						name: blockDetail.name ? blockDetail.name : "detailName",
						type: Object.values(DetailTypeE).find((type) => type === blockDetail.type)
							? blockDetail.type
							: DetailTypeE.TXT,
						subtype: validateSubType(blockDetail),
						local: blockDetail.local ? blockDetail.local : "",
						title: blockDetail.title ? blockDetail.title : "",
						alias: blockDetail.alias && blockDetail.alias !== "" ? blockDetail.alias.toString() : undefined,
						visible: blockDetail.visible && blockDetail.visible === true ? true : false,
						style: blockDetail.style ? validateStyles(blockDetail) : null,
						details: addrDetailsToSet
					};

					blockDetailsToSet.push(blockDetailToSet);
				}
			}

			const blockToSet: IConfigBlock = {
				id: !isNaN(block.id) ? block.id : 0,
				name: block.name ? block.name : "blockName",
				type: block.type === DetailTypeE.CFBLK || block.type === DetailTypeE.CUBLK ? block.type : DetailTypeE.BLK,
				local: block.local ? block.local : "",
				title: block.title ? block.title : "",
				visible: block.visible ? block.visible : false,
				style: block.style && block.style.indexOf(StyleTypeE.HGH) >= 0 ? [StyleTypeE.HGH] : null,
				details: blockDetailsToSet
			};

			validatedBlocks.push(blockToSet);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const blocksToReturn: IConfigBlock[] = checkAppForNewestDetails(validatedBlocks);
	blocksToReturn.sort(function(a, b) {
		return a.id - b.id;
	});

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	return writeNewBlocksWithIds(blocksToReturn);
};

/**
 * Transforms data arrived as AsnClientPersistenceEventArgument (= json) to an ICustomerLabels object
 * @param asnClientPersistenceEventArgument - customer labels data from the ClientPersistenceStore
 * @returns the data as an ICustomerLabels object
 */
export const asnJsonToCustomLabels = (
	asnClientPersistenceEventArgument: AsnClientPersistenceEventArgument
): IKeyValuePair[] => {
	let custLabels: IKeyValuePair[] = (customLabelsInitialData as unknown as ICustomerLabels).CustLabels;
	if (
		asnClientPersistenceEventArgument &&
		asnClientPersistenceEventArgument.changedItems &&
		asnClientPersistenceEventArgument.changedItems[0] &&
		asnClientPersistenceEventArgument.changedItems[0].data
	) {
		try {
			const requestData: ICustomerLabels = JSON.parse(
				asnClientPersistenceEventArgument.changedItems[0].data
			) as ICustomerLabels;

			if (
				requestData &&
				requestData.CustLabels &&
				requestData.CustLabels.length !== undefined &&
				requestData.CustLabels.length > 0
			) {
				custLabels = requestData.CustLabels;
				console.log("Downloading Customer Label Configurations.");
			}
		} catch (e) {
			const message = "Error while parsing JSON to customer labels";
			theSharedLogger.error(
				message,
				"asnJsonToCustomLabels",
				{ className: "ContactDetailsHelper" },
				{ error: message + ", " + e }
			);
			console.log("No configurations available for customer labels, using initial configurations.");
		}
	} else {
		const message = "Error while parsing JSON to customer labels";
		theSharedLogger.error(message, "asnJsonToCustomLabels", { className: "ContactDetailsHelper" }, { error: message });
		console.log("No configurations available for customer labels, using initial configurations.");
	}
	return custLabels;
};

/**
 * Transforms data arrived as AsnClientPersistenceEventArgument (= json) to an array of IConfigBlocks
 * @param asnClientPersistenceEventArgument - a configuration for the contact details in json format from the ClientPersistenceStore
 * @returns an array with IConfigblocks
 */
export const asnJsonConfigCDToBlocks = (
	asnClientPersistenceEventArgument: AsnClientPersistenceEventArgument
): IConfigBlock[] => {
	let cdInitData: IModelJsonConfigCD = configCDInitialData as unknown as IModelJsonConfigCD;

	if (
		asnClientPersistenceEventArgument &&
		asnClientPersistenceEventArgument.changedItems &&
		asnClientPersistenceEventArgument.changedItems[0] &&
		asnClientPersistenceEventArgument.changedItems[0].data
	) {
		try {
			const requestData: IModelJsonConfigCD = JSON.parse(
				asnClientPersistenceEventArgument.changedItems[0].data
			) as IModelJsonConfigCD;

			if (
				requestData &&
				requestData.u8sContactDetails &&
				requestData.u8sContactDetails.length !== undefined &&
				requestData.u8sContactDetails.length > 0
			) {
				console.log("Downloading Contact Details Configurations.");
				cdInitData = requestData;
			}
		} catch (e) {
			const message = "Error while parsing JSON to config CD blocks";
			theSharedLogger.error(
				message,
				"asnJsonConfigCDToBlocks",
				{ className: "ContactDetailsHelper" },
				{ error: message + ", " + e }
			);
			console.log("No configurations available for contact details, using initial configurations.");
		}
	} else {
		const message = "Error while parsing JSON to config CD blocks";
		theSharedLogger.error(
			message,
			"asnJsonConfigCDToBlocks",
			{ className: "ContactDetailsHelper" },
			{ error: message }
		);
		console.log("No configurations available for contact details, using initial configurations.");
	}
	return jsonDetailsToBlocks(cdInitData);
};

/**
 * Transforms data arrived as AsnClientPersistenceEventArgument to an IConfigCW object
 * @param asnClientPersistenceEventArgument - contact details data from the ClientPersistenceStore
 * @returns the data as an IConfigCW object
 */
export const asnJsonConfigCWToConfig = (
	asnClientPersistenceEventArgument: AsnClientPersistenceEventArgument
): IConfigCW => {
	const cwInitData = configCWInitialData as unknown as IModelJsonConfigCW;

	const configCW: IConfigCW = {
		Functionalities: jsonToFunctionalities(cwInitData.Functionalities),
		RemoteContact: jsonDetailsToBlocks(cwInitData.RemoteContact),
		HoldContact: jsonDetailsToBlocks(cwInitData.HoldContact),
		LocalContact: jsonDetailsToBlocks(cwInitData.LocalContact),
		RedirectContact: jsonDetailsToBlocks(cwInitData.RedirectContact)
	};

	let rcExist = false;
	let hcExist = false;
	let lcExist = false;
	let rdcExist = false;

	if (
		asnClientPersistenceEventArgument &&
		asnClientPersistenceEventArgument.changedItems &&
		asnClientPersistenceEventArgument.changedItems[0] &&
		asnClientPersistenceEventArgument.changedItems[0].data
	) {
		try {
			const requestData: IModelJsonConfigCW | undefined = JSON.parse(
				asnClientPersistenceEventArgument.changedItems[0].data
			) as IModelJsonConfigCW;

			if (requestData) {
				if (
					requestData.Functionalities &&
					requestData.Functionalities.length !== undefined &&
					requestData.Functionalities.length > 0
				)
					configCW.Functionalities = requestData.Functionalities;

				rcExist =
					requestData.RemoteContact &&
					requestData.RemoteContact.u8sContactDetails &&
					requestData.RemoteContact.u8sContactDetails.length !== undefined &&
					requestData.RemoteContact.u8sContactDetails.length > 0;

				if (rcExist)
					configCW.RemoteContact = jsonDetailsToBlocks(requestData.RemoteContact);

				hcExist =
					requestData.HoldContact &&
					requestData.HoldContact.u8sContactDetails &&
					requestData.HoldContact.u8sContactDetails.length !== undefined &&
					requestData.HoldContact.u8sContactDetails.length > 0;

				if (hcExist)
					configCW.HoldContact = jsonDetailsToBlocks(requestData.HoldContact);

				lcExist =
					requestData.LocalContact &&
					requestData.LocalContact.u8sContactDetails &&
					requestData.LocalContact.u8sContactDetails.length !== undefined &&
					requestData.LocalContact.u8sContactDetails.length > 0;

				if (lcExist)
					configCW.LocalContact = jsonDetailsToBlocks(requestData.LocalContact);

				rdcExist =
					requestData.RedirectContact &&
					requestData.RedirectContact.u8sContactDetails &&
					requestData.RedirectContact.u8sContactDetails.length !== undefined &&
					requestData.RedirectContact.u8sContactDetails.length > 0;

				if (rdcExist)
					configCW.RedirectContact = jsonDetailsToBlocks(requestData.RedirectContact);
			}
		} catch (e) {
			const message = "Error while parsing JSON to config CW blocks";
			theSharedLogger.error(
				message,
				"asnJsonConfigCWToConfig",
				{ className: "ContactDetailsHelper" },
				{ error: message + ", " + e }
			);
			console.log("No configurations available for conversation windows, using initial configurations.");
		}
	}

	if (rcExist || hcExist || lcExist || rdcExist)
		console.log("Downloading Conversation Windows Configurations.");
	else {
		const message = "Error while parsing JSON to config CW blocks";
		theSharedLogger.error(
			message,
			"asnJsonConfigCWToConfig",
			{ className: "ContactDetailsHelper" },
			{ error: message }
		);
		console.log("No configurations found for conversation windows, using initial configurations.");
	}
	return configCW;
};

/**
 * Creates the content details from an incoming AsnNetDatabaseContact with empty dynamic status infos.
 * @param entryContentJson - entry AsnNetDatabaseContact (JSON)
 * @returns an IContentDetails object.
 */
export const asnContactJsonToContent = (entryContentJson: AsnNetDatabaseContact): IContentDetails => {
	// -- u8sDetails --
	const u8sDetails: Map<string, string> = new Map<string, string>();

	let content: keyof AsnNetDatabaseContact;
	for (content in entryContentJson) {
		const key = entryContentJson[content];
		if (
			key !== "optionalParams" &&
			key !== "statusInfos" &&
			key !== "customFields" &&
			U8sAllPermittedDetails.find((detail: IPermittedDetail) => detail.name === content && detail.vis === true)
		)
			u8sDetails.set(content, key as string);
	}

	// -- contentPic --
	const optParams: AsnOptionalParam[] | undefined = entryContentJson.optionalParams as AsnOptionalParam[];
	const contentPic: IContentPic = { jpegPhoto: "", jpegPhotoHash: "" };

	if (optParams) {
		for (const [key, param] of Object.entries(optParams)) {
			if (key === "jpegPhoto") {
				for (const [innerkey, innerValue] of Object.entries(param)) {
					if (innerkey === "binarydata")
						contentPic.jpegPhoto = innerValue as string;
				}
			}
			if (key === "jpegPhotoHash") {
				for (const [innerkey, innerValue] of Object.entries(param)) {
					if (innerkey === "binarydata")
						contentPic.jpegPhotoHash = innerValue as string;
				}
			}
		}
	}

	// -- customfields && contactUrls --
	const entryCustomFields: AsnStringPairList | undefined = entryContentJson.customFields;
	const customFields: IKeyValuePair[] = [];
	const contactURLs: IKeyValuePair[] = [];

	if (entryCustomFields && entryCustomFields.length && entryCustomFields.length > 0) {
		for (let i = 0; i < entryCustomFields.length; i++) {
			if (!entryCustomFields[i])
				break;

			const entryCustomKey: string = entryCustomFields[i].key ? entryCustomFields[i].key : "";

			if (entryCustomKey.startsWith("Custom"))
				customFields.push({ key: entryCustomKey, value: entryCustomFields[i].value });
			else if (entryCustomKey.startsWith("ContactURL"))
				contactURLs.push({ key: entryCustomKey, value: entryCustomFields[i].value });
		}
	}

	const contentDetails: IContentDetails = {
		contentPic,
		u8sDetails,
		statusInfos: EmptyStatusInfos(),
		customFields,
		contactURLs
	};

	return contentDetails;
};

/**
 * Extract all allowed phone numbers from an incoming AsnNetDatabaseContact and return an array with KeyValuePairs.
 * @param contact - entry AnsNetDatabaseContact
 * @param customLabels - entry customer labels (JSON)
 * @param config - entry config (JSON)
 * @param formatted - format result?
 * @returns an array with KeyValuePairs
 */
export const getPhoneNumbersFromAsnContact = (
	contact: AsnNetDatabaseContact,
	customLabels: IKeyValuePair[],
	config: IConfigBlock[],
	formatted?: boolean
): IKeyValuePair[] => {
	const allPossiblePhones: string[] = [
		"u8sPhoneBusiness",
		"u8sPhoneBusiness2",
		"u8sCompanyMainTelephoneNumber",
		"u8sAssistantTelephoneNumber",
		"u8sPhoneHome",
		"u8sPhoneHome2",
		"u8sPrimaryTelephoneNumber",
		"u8sPhoneMobile",
		"u8sCarTelephoneNumber",
		"u8sRadioTelephoneNumber",
		"u8sPagerTelephoneNumber",
		"u8sOtherTelephoneNumber",
		"u8sCallbackTelephoneNumber",
		"u8sISDNTelephoneNumber",
		"u8sTTYTTDTelephoneNumber"
	];

	const custLabelMap: Map<string, string> = new Map<string, string>();
	if (customLabels.length !== undefined && customLabels.length > 0) {
		for (let i = 0; i < customLabels.length; i++)
			custLabelMap.set(customLabels[i].key, customLabels[i].value);
	}

	const phoneNumbersToShow: IKeyValuePair[] = [];

	allPossiblePhones.map((phone) => {
		for (const [key, value] of Object.entries(contact)) {
			if (key === phone) {
				const custLabel: string | undefined = custLabelMap.get(phone);
				let local = "";
				let visible = false;

				if (config && config) {
					config.find((block: IConfigBlock) => {
						if (block.details === null)
							return false;

						return block.details.some((detail: IConfigU8sDetail) => {
							if (detail.name === phone) {
								local = detail.local;
								visible = detail.visible;
							}
							return detail.name === phone;
						});
					});
				}
				if (value && visible) {
					const phoneNumber = PhoneNumberFormatHelper.getPhoneNumber(value);
					phoneNumbersToShow.push({
						key: custLabel ? custLabel : local,
						value: formatted && phoneNumber.isValid ? phoneNumber.toDisplay : phoneNumber.rawInput
					});
				}
			}
		}
		return true;
	});
	return phoneNumbersToShow;
};

/**
 * Extract all allowed email addresses from an incoming AsnNetDatabaseContact and return an array with KeyValuePairs.
 * @param contact - entry AnsNetDatabaseContact
 * @param customLabels - entry customer labels (JSON)
 * @param config - entry config (JSON)
 * @returns an array with KeyValuePairs
 */
export const getEmailsFromAsnContact = (
	contact: AsnNetDatabaseContact,
	customLabels: IKeyValuePair[],
	config: IConfigBlock[]
): IKeyValuePair[] => {
	const allPossibleEmails: string[] = ["u8sEMail", "u8sEMail2", "u8sEMail3"];

	const custLabelMap: Map<string, string> = new Map<string, string>();
	if (customLabels && customLabels.length !== undefined && customLabels.length > 0) {
		for (let i = 0; i < customLabels.length; i++)
			custLabelMap.set(customLabels[i].key, customLabels[i].value);
	}

	const emailsToShow: IKeyValuePair[] = [];

	allPossibleEmails.map((email) => {
		for (const [key, value] of Object.entries(contact)) {
			if (key === email) {
				const custLabel: string | undefined = custLabelMap.get(email);
				let local = "";
				let visible = false;

				if (config && config) {
					config.find((block: IConfigBlock) => {
						if (block.details === null)
							return false;

						return block.details.some((detail: IConfigU8sDetail) => {
							if (detail.name === email) {
								local = detail.local;
								visible = detail.visible;
							}
							return detail.name === email;
						});
					});
				}
				if (visible && value && value !== "")
					emailsToShow.push({ key: custLabel ? custLabel : local, value: value + "" });
			}
		}
		return true;
	});

	return emailsToShow;
};

/**
 * This function checks whether a permitted detail has been added lately
 * that the client does not yet have in his config files.
 * @param entryBlocks - blocks to check
 * @returns the checked blocks
 */
const checkAppForNewestDetails = (entryBlocks: IConfigBlock[]): IConfigBlock[] => {
	const exitBlocks: IConfigBlock[] = cloneDeep(entryBlocks);

	const checkBlocksForNewestDetails = (): Map<string, IPermittedDetail> => {
		const permittedDetailsMap: Map<string, IPermittedDetail> = PermittedDetailsMap();
		const returnMap: Map<string, IPermittedDetail> = new Map<string, IPermittedDetail>();
		const checkArray: string[] = [];

		// Check if the required details are already existing
		for (const box of exitBlocks) {
			const boxDetails: IConfigU8sDetail[] = box.details;

			for (const boxDetail of boxDetails) {
				if (permittedDetailsMap.has(boxDetail.name))
					checkArray.push(boxDetail.name);

				const addrDetails: IConfigU8sDetail[] | null = boxDetail.details;
				if (addrDetails) {
					for (const addrDetail of addrDetails) {
						if (permittedDetailsMap.has(addrDetail.name))
							checkArray.push(addrDetail.name);
					}
				}
			}
		}

		for (const [key, value] of permittedDetailsMap) {
			// Keep only Keep only the details not found with correct initial block information
			const blockToPlace: string | null | undefined = value.initialBlockLocal;
			const foundElement: string | undefined = checkArray.find((str) => {
				return str === key;
			});
			if (!foundElement && blockToPlace)
				returnMap.set(key, value);
		}
		return returnMap;
	};

	const newestDetailsMap: Map<string, IPermittedDetail> = checkBlocksForNewestDetails();

	// Insert the new details
	if (newestDetailsMap.size > 0) {
		for (const [key, value] of newestDetailsMap) {
			const newDetailName: string = key;
			const newDetailInfo: IPermittedDetail = value;
			const blockToPlace: string | null | undefined = newDetailInfo?.initialBlockLocal;

			const type: DetailTypeE = newDetailInfo?.type as DetailTypeE;
			const subtype: SubTypeE = newDetailInfo?.subtype as SubTypeE;
			const newDetail: IConfigU8sDetail = {
				id: "",
				name: newDetailName,
				type: type ? type : DetailTypeE.TXT,
				subtype: subtype ? subtype : SubTypeE.EMPTY,
				local: newDetailInfo?.local ? newDetailInfo?.local : "",
				title: "",
				visible: true,
				style: null,
				details: null
			};

			const newAddress: IConfigU8sDetail = {
				id: "",
				name: "address",
				type: DetailTypeE.ADR,
				subtype: SubTypeE.EMPTY,
				local: blockToPlace ? blockToPlace : "",
				title: "",
				visible: true,
				style: null,
				details: []
			};

			if (
				newDetail.type === DetailTypeE.CTY ||
				newDetail.type === DetailTypeE.CTR ||
				newDetail.type === DetailTypeE.PLZ ||
				newDetail.type === DetailTypeE.STR ||
				newDetail.type === DetailTypeE.STT
			) {
				let addressBoxExists = false;

				if (newAddress && newAddress.details)
					newAddress.details.push(newDetail);

				for (const block of exitBlocks) {
					if (block.local === "IDS_CD_ADDRESSES") {
						let addressExists = false;
						addressBoxExists = true;

						for (const address of block.details) {
							if (address.local === blockToPlace && address.details) {
								address.details.push(newDetail);
								addressExists = true;
								newestDetailsMap.delete(newDetailName);
							}
						}

						if (!addressExists)
							block.details.push(newAddress);
					}
				}

				if (!addressBoxExists) {
					const newAddressBoxWithAddresses: IConfigBlock = {
						id: 0,
						name: "addressBlock",
						type: DetailTypeE.BLK,
						local: "IDS_CD_ADDRESSES",
						title: "",
						visible: false,
						style: null,
						details: [newAddress]
					};
					exitBlocks.push(newAddressBoxWithAddresses);
					newestDetailsMap.delete(newDetailName);
				}
			} else {
				let blockExist = false;

				for (const block of exitBlocks) {
					if (block.local === blockToPlace && block.details) {
						block.details.splice(0, 0, newDetail);
						blockExist = true;
					}
				}

				if (!blockExist) {
					const newBoxWithDetail: IConfigBlock = {
						id: 0,
						name: "extraBlock",
						type: DetailTypeE.BLK,
						local: blockToPlace ? blockToPlace : "",
						title: "",
						visible: false,
						style: null,
						details: [newDetail]
					};
					exitBlocks.splice(0, 0, newBoxWithDetail);
				}
			}
		}
	}

	return exitBlocks;
};

/**
 * Function to rewrite a config block with correctly derived IDs for sorting
 * @param entryConfigBlocks - configBlock to rewrite
 * @returns a IConfigBlock
 */
export const writeNewBlocksWithIds = (entryConfigBlocks: IConfigBlock[]): IConfigBlock[] => {
	const newConfigBlocks: IConfigBlock[] = [];

	let blockIdCounter = 0;

	if (entryConfigBlocks) {
		const clonedConfigBlocks = cloneDeep(entryConfigBlocks);

		for (const block of clonedConfigBlocks) {
			if (!block || !block.details)
				break;

			if (block.details.length && block.details.length > 0) {
				const clonedBlock: IConfigBlock = cloneDeep(block);

				if (!clonedBlock)
					break;

				clonedBlock.id = blockIdCounter;

				if (clonedBlock.name)
					clonedBlock.name = clonedBlock.name.startsWith("block") ? "block" + blockIdCounter : clonedBlock.name;
				else
					clonedBlock.name = "block" + blockIdCounter;

				if (clonedBlock.details && clonedBlock.details.length && clonedBlock.details.length > 0) {
					const idPartDetail: string = "d" + (blockIdCounter < 10 ? "0" + blockIdCounter : blockIdCounter.toString());

					clonedBlock.details.map((detail: IConfigU8sDetail, index: number) => {
						detail.id = idPartDetail + index;
						return detail;
					});
				}

				blockIdCounter = blockIdCounter + 1;

				newConfigBlocks.push(clonedBlock);
			}
		}
	}
	return newConfigBlocks;
};
