import "./presenceTooltip.scss";
import {
	faArrowLeft, faArrowRight,
	faCalendar, faCar, faCircleInfo, faCircleO,
	faClose,
	faHouse, faLocationPin, faMessage, faMobileButton,
	faMugSaucer, faPhone, faTreePalm, faVideo
} from "web-fortawesome/npm/pro-light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import {
	AsnPresenceStateFlags, EnumCallState
} from "../../asn1/EUCSrv/stubs/ENetROSEInterface";
import { AsnAgentStateEnum } from "../../asn1/EUCSrv/stubs/ENetUC_Agent";
import { AsnReachabilityEnum } from "../../asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { theSharedBrowser, theSharedTranslationManager } from "../../helpers/helpers";
import { IContactContainer } from "../../interfaces/interfaces";
import { IKeyValuePair } from "../../interfaces/IKeyValuePair";
import {
	IConfigBlock
} from "../../interfaces/interfacesConfig";
import { getIcon } from "../icons/SvgIcons";
import { iPresenceStateToString } from "../userPresenceIcon/UserPresenceHelper";
import {
	EnumAppointmentSensitivity,
	EnumAppointmentState
} from "../../asn1/EUCSrv/stubs/ENetUC_Common_Appointments";
import {
	AsnPresenceForward,
	AsnPresenceLine
} from "../../asn1/EUCSrv/stubs/ENetUC_PresenceV2";
import { IAsnAppointmentEntry } from "../../interfaces/serializable/IAsnAppointmentEntry";
import UserPresenceIcon from "../userPresenceIcon/UserPresenceIcon";
import { ClickAwayListener } from "@mui/material";
import PhoneNumberFormatHelper from "../../helpers/PhoneNumberFormatHelper";

interface IProps {
	contact: IContactContainer | undefined;
	customLabels: IKeyValuePair[];
	configCD: IConfigBlock[];
	currentAppointment: string[];
	nextAppointment: string;
	close: () => void;
}

/**
 *
 */
// eslint-disable-next-line react/display-name, @typescript-eslint/no-unnecessary-type-arguments
export const PresenceTooltip = React.forwardRef<HTMLElement, IProps>((props: IProps, ref) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const nextValidAppointment = props.contact?.nextAppointment !== undefined && props.contact.nextAppointment.bIsValid ? props.contact.nextAppointment : undefined;
	const [teamsStatusText, setTeamsStatusText] = useState<string>("");

	useEffect(() => {
		if (ref) {
			const x = ref as React.MutableRefObject<HTMLDivElement>;
			setAnchorEl(x.current);
		}
	}, [ref]);

	/**
	 * Get IDS label, used for several checks
	 */
	const presenceLabel = useMemo(() => {
		return iPresenceStateToString(props.contact?.presence, true);
	}, [props.contact?.presence]);

	/**
	 * Contact name
	 */
	const contactName = useMemo(() => {
		if (!props.contact)
			return "";
		let name: string | undefined;
		if (props.contact?.asnNetDatabaseContact === undefined)
			name = theSharedTranslationManager.getTranslation("IDS_UNKNOWN_USER");
		else
			name = props.contact?.asnNetDatabaseContact?.u8sDisplayName;
		return name ?? "";
	}, [props.contact]);

	/**
	 * Phone lines
	 */
	const seqPhoneLines = useMemo(() => {
		return props.contact?.seqPhoneLines ?? [];
	}, [props.contact?.seqPhoneLines]);

	/**
	 * Active or ringing call, needed to know the priority
	 */
	const activeOrRingingCall = useMemo(() => {
		if (!props.contact?.seqCalls || !props.contact?.seqCalls.length)
			return null;
		// Priority to connected call
		const connectedCall = props.contact?.seqCalls.find(item => item.iCallState === EnumCallState.eCALLSTATECONNECTED);
		const ringingOutBound = props.contact?.seqCalls.find(item => item.iCallState === EnumCallState.eCALLSTATERINGBACK);
		const ringingInBound = props.contact?.seqCalls.find(item => item.iCallState === EnumCallState.eCALLSTATEOFFERING);
		const call = connectedCall || ringingOutBound || ringingInBound;
		if (!call)
			return null;
		return call;
	}, [props.contact?.seqCalls]);

	/**
	 * Valid appointments
	 */
	const validAppointments = useMemo(() => {
		if (!props.contact?.currentAppointments)
			return undefined;
		const currentAppointments = props.contact?.currentAppointments;
		let validAppointments: IAsnAppointmentEntry[] = [];
		for (const appointment of currentAppointments) {
			const isValid = appointment?.bIsValid ? appointment?.bIsValid : false;
			if (isValid)
				validAppointments.push(appointment);
		}
		if (validAppointments.length > 3)
			validAppointments = validAppointments.slice(0, 3);
		return validAppointments;
	}, [props.contact?.currentAppointments]);

	const reachability = useMemo(() => {
		return props.contact?.absentState?.iReachability;
	}, [props.contact?.absentState?.iReachability]);

	const getNextAppointmentTime = () => {
		return props.nextAppointment;
	};

	const getNextAppointmentSubject = () => {
		let nextAppointmentSubject = nextValidAppointment?.u8sSubject;

		if (nextValidAppointment && nextValidAppointment.iSensitivity) {
			switch (nextValidAppointment.iSensitivity) {
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYNORMAL:
					break;
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPRIVATE:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYCONFIDENTIAL:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPERSONAL:
					nextAppointmentSubject = "(" + theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_SENSITIVITY_PRIVATE") + ")";
					break;
				default:
					break;
			}
		}
		return nextAppointmentSubject;
	};

	const getCurrentAppointmentTime = (index: number) => {
		return props.currentAppointment[index];
	};

	const getCurrentAppointmentSubject = (appointment: IAsnAppointmentEntry) => {
		let appointmentSubject = appointment?.u8sSubject;

		if (appointment && appointment.iSensitivity) {
			switch (appointment.iSensitivity) {
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYNORMAL:
					break;
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPRIVATE:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYCONFIDENTIAL:
				case EnumAppointmentSensitivity.eAPPOINTMENTSENSITIVITYPERSONAL:
					appointmentSubject = "(" + theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_SENSITIVITY_PRIVATE") + ")";
					break;
				default:
					break;
			}
		}
		return appointmentSubject;
	};

	const getCalendarStatus = (appointment: IAsnAppointmentEntry) => {
		let calendarStatus = "ptt-appointments-calendar-icon-default-state";

		if (appointment && appointment.iStatus) {
			switch (appointment.iStatus) {
				case EnumAppointmentState.eAPPOINTMENTSTATUSBUSY:
					calendarStatus = "ptt-appointments-calendar-icon-busy-state";
					break;
				case EnumAppointmentState.eAPPOINTMENTSTATUSOUTOFOFFICE:
					calendarStatus = "ptt-appointments-calendar-icon-outofoffice-state";
					break;
				default:
					calendarStatus = "ptt-appointments-calendar-icon-default-state";
					break;
			}
		}
		return calendarStatus;
	};

	const getNextAppointmentCalendarStatus = () => {
		let calendarStatus = "ptt-next-appointment-calendar-icon-default-state";

		if (nextValidAppointment && nextValidAppointment.iStatus) {
			switch (nextValidAppointment.iStatus) {
				case EnumAppointmentState.eAPPOINTMENTSTATUSBUSY:
					calendarStatus = "ptt-next-appointment-calendar-icon-busy-state";
					break;
				case EnumAppointmentState.eAPPOINTMENTSTATUSOUTOFOFFICE:
					calendarStatus = "ptt-next-appointment-calendar-icon-outofoffice-state";
					break;
				default:
					calendarStatus = "ptt-next-appointment-calendar-icon-default-state";
					break;
			}
		}
		return calendarStatus;
	};

	const teamsAvailable = useMemo(() => {
		return !!props.contact?.msTeamsEmail?.email;
	}, [props.contact?.msTeamsEmail]);

	const getTeamsStatus = (): string => {
		let teamsStatus = "ptt-channels-teams-svg-icon";

		if (props.contact?.msTeamsEmail) {
			switch (iPresenceStateToString(props?.contact?.msTeamsEmail?.value)) {
				case "available":
				case "unavailable":
					teamsStatus = "ptt-channels-teams-svg-icon";
					break;
				case "busy":
				case "dnd":
					teamsStatus = "ptt-channels-teams-svg-icon-busy";
					break;
				case "absent":
					teamsStatus = "ptt-channels-teams-svg-icon-absent";
					break;
				default:
					teamsStatus = "ptt-channels-teams-svg-icon";
					break;
			}
		}
		return teamsStatus;
	};

	const getTeamsStatusForOuterCircle = () => {
		let teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-black";

		if (props.contact?.msTeamsEmail) {
			switch (iPresenceStateToString(props?.contact?.msTeamsEmail?.value)) {
				case "available":
				case "unavailable":
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-black";
					break;
				case "busy":
				case "dnd":
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-red";
					break;
				case "absent":
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-yellow";
					break;
				default:
					teamsStatusForOuterCircle = "ptt-channels-teams-outer-circle-border-black";
					break;
			}
		}
		return teamsStatusForOuterCircle;
	};

	const getReachabilityTypeIcon = () => {
		let iconDefinition = faMugSaucer;

		if (reachability === AsnReachabilityEnum.eREACHABILITYBUSINESSTRIP)
			iconDefinition = faCar;
		else if (reachability === AsnReachabilityEnum.eREACHABILITYHOMEOFFICE)
			iconDefinition = faHouse;
		else if (reachability === AsnReachabilityEnum.eREACHABILITYVACATION)
			iconDefinition = faTreePalm;

		return iconDefinition;
	};

	const getReachabilityText = () => {
		let reachabilityText = "";

		if (reachability === AsnReachabilityEnum.eREACHABILITYPAUSE)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_PAUSE");
		else if (reachability === AsnReachabilityEnum.eREACHABILITYBUSINESSTRIP)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_BUSINESS_TRIP");
		else if (reachability === AsnReachabilityEnum.eREACHABILITYHOMEOFFICE)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_REMOTE_WORKING");
		else if (reachability === AsnReachabilityEnum.eREACHABILITYVACATION)
			reachabilityText = theSharedTranslationManager.getTranslation("IDS_REACHABILITY_VACATION");

		return reachabilityText;
	};

	const getAgentStateIcon = () => {
		let iconDefinition = faCircleO;

		if (props.contact?.agentState === AsnAgentStateEnum.eAGENTSTATEREADY || AsnAgentStateEnum.eAGENTSTATEBUSY || AsnAgentStateEnum.eAGENTSTATENOTREADY)
			iconDefinition = faCircleO;

		return iconDefinition;
	};

	const getAgentStateText = () => {
		let agentStateText = "";
		if (props.contact?.agentState === AsnAgentStateEnum.eAGENTSTATEREADY)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_READY");
		else if (props.contact?.agentState === AsnAgentStateEnum.eAGENTSTATEBUSY)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_BUSY");
		else if (props.contact?.agentState === AsnAgentStateEnum.eAGENTSTATENOTREADY)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_NOTREADY");
		else if (props.contact?.agentState === AsnAgentStateEnum.eAGENTSTATEWORKINGAFTERCALL)
			agentStateText = theSharedTranslationManager.getTranslation("IDS_AGENT_STATE_WORKINGAFTERCALL");

		return agentStateText;
	};

	const getMobileAvailability = (): boolean => {
		if (!props?.contact?.presence)
			return false;
		return !!(props?.contact?.presence & AsnPresenceStateFlags.eMOBILEAVAILABLITY);
	};

	const getPhoneLineChannelSection = (phoneLine: AsnPresenceLine, index: number) => {
		let div = null;
		if (phoneLine && phoneLine.u8sLinePhoneNumber.search("Business") !== -1) {
			div = <div key={index} className="ptt-channels-dial-in">
				{phoneLine.iDoNotDisturb === 1
					? getIcon("phoneDoNotDisturb", "small", "plain", "ptt-channels-videochat-do-not-disturb-svg")
					: <FontAwesomeIcon
						className="ptt-channels-dial-in-icon"
						transform={"shrink-1"}
						icon={faPhone}
					/>
				}
				<Typography className={"ptt-information-text"}>
					[{phoneLine.u8sLineNumber.substring(15, 12)}] {phoneLine.iDoNotDisturb === 1 ? " " + theSharedTranslationManager.getTranslation("IDS_CHANNEL_CALL_PROTECTION") : ""}
				</Typography>
			</div>;
		}
		if (phoneLine && phoneLine.u8sLinePhoneNumber.search("phys=ClientAV") !== -1) {
			div = <div key={index} className={"ptt-channels-videochat-availability"}>
				{props?.contact?.clientCapabilities?.handleVideo
					? <>
						{phoneLine.iDoNotDisturb === 1
							? getIcon("videochatDoNotDisturb", "small", "plain", "ptt-channels-videochat-do-not-disturb-svg")
							: <FontAwesomeIcon
								className="ptt-channels-videochat-icon"
								transform={"shrink-1"}
								icon={faVideo}
							/>
						}
					</>
					: <span className={"ptt-icon"}>
						{getIcon("videochatNoDevice", "small", "plain", "")}
					</span>
				}

				<Typography className={"ptt-information-text"}>{[theSharedTranslationManager.getTranslation("IDS_CHAT_MSG_VIDEOCHAT")]}</Typography>
			</div>;
		}
		if (phoneLine && phoneLine.u8sLinePhoneNumber.search("phys=Mobile") !== -1) {
			const phoneNumber = PhoneNumberFormatHelper.getPhoneNumber(phoneLine.u8sLineNumber);
			div = <div key={index} className={"ptt-channels-dial-in"}>
				{phoneLine.iState === 1
					? <>
						<FontAwesomeIcon
							className="ptt-channels-dial-in-icon"
							transform={"shrink-1"}
							icon={faPhone}
						/>
						<Typography className={"ptt-channels-dial-in-number"}>[{phoneNumber.toDisplay}]</Typography>
					</>
					: <>
						<span className={"ptt-icon"}>
							{getIcon("phoneOffline", "small", "plain", "ptt-channels-line-forward-svg-icon")}
						</span>
						<Typography className={"ptt-channels-dial-in-number"}>[{phoneNumber.toDisplay}] {" " + theSharedTranslationManager.getTranslation("IDS_LINE_OFFLINE")}</Typography>
					</>
				}
			</div>;
		}
		return div;
	};

	useEffect(() => {
		let message = "";
		if (props.contact?.presence) {
			if (props.contact?.presence & AsnPresenceStateFlags.eCALL)
				message = theSharedTranslationManager.getTranslation("IDS_CHANNEL_CONVERSATION");

			else if (props.contact?.presence & AsnPresenceStateFlags.eCONFERENCING)
				message = theSharedTranslationManager.getTranslation("IDS_CHANNEL_MEETING");
		}

		if (props.contact?.msTeamsEmail && message === "") {
			switch (iPresenceStateToString(props.contact.msTeamsEmail?.value)) {
				case "available":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_AVAILABLE");
					break;
				case "busy":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_BUSY");
					break;
				case "absent":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_ABSENT");
					break;
				case "dnd":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_DND");
					break;
				case "unavailable":
					message = theSharedTranslationManager.getTranslation("IDS_PRESENCE_OFFLINE");
					break;
				default:
					message = theSharedTranslationManager.getTranslation("");
					break;
			}
		}
		setTeamsStatusText(message);
	}, [props.contact?.msTeamsEmail, props.contact?.presence]);

	/**
	 * Status text only for meeting, call connected or ringing.
	 * Empty string for another logic.
	 */
	const callOrMeetingStatusText = useMemo(() => {
		if (activeOrRingingCall && activeOrRingingCall.iCallState === EnumCallState.eCALLSTATECONNECTED)
			return theSharedTranslationManager.getTranslation("IDS_CALL_CONNECTED");
		else {
			if (presenceLabel === "IDS_HAS_MEETING" || presenceLabel === "IDS_CALL_CONNECTED" || presenceLabel === "IDS_CALL_RINGING")
				return theSharedTranslationManager.getTranslation(presenceLabel);
		}
		return "";
	}, [presenceLabel, activeOrRingingCall]);

	/**
	 * Display status text for "in a call"
	 */
	const inACall = useMemo(() => {
		if (!activeOrRingingCall)
			return null;
		const phoneNumber = PhoneNumberFormatHelper.getPhoneNumber(activeOrRingingCall.u8sCurrentNumberSC);
		return (
			<>
				{!activeOrRingingCall.iOutbound
					? <FontAwesomeIcon className="ptt-heading-arrow-right" transform={"shrink-1"} icon={faArrowLeft} />
					: <FontAwesomeIcon className="ptt-heading-arrow-left" transform={"shrink-1"} icon={faArrowRight} />}
				<Typography className={"ptt-information-text"}> {" " + (activeOrRingingCall.remoteContact?.u8sDisplayName ?? phoneNumber.toDisplay)} </Typography>
			</>
		);
	}, [activeOrRingingCall]);

	const handleKeyDown = (event: React.KeyboardEvent): void => {
		if (event.key === "Escape" ||
			event.key === "Tab" ||
			event.key === "Enter" ||
			event.code === "Space" ||
			event.key === "ArrowRight" ||
			event.key === "ArrowLeft" ||
			event.key === "ArrowUp" ||
			event.key === "ArrowDown"
		) {
			event.preventDefault();
			event.stopPropagation();
			props.close();
		}
	};

	return (
		<ClickAwayListener onClickAway={() => { props.close(); }}>
			<Menu
				id="basic-menu"
				data-cy={"shared-presence-tooltip"}
				anchorEl={anchorEl}
				onClose={() => { props.close(); }}
				open={Boolean(anchorEl)}
				MenuListProps={{
					id: "basic-button",
					"aria-labelledby": "basic-button"
				}}
				sx={{ pointerEvents: "none" }}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					props.close();
				}}
				onKeyDown={handleKeyDown}
			>
				<div id={"ptt-main"}>
					<div className={"ptt-heading"}>

						<div className={"ptt-heading-name-section"}>
							<Typography className={"ptt-heading-username"}>{contactName}</Typography>
							{theSharedBrowser.is.touchScreen
								? <>
									<IconButton
										name="plain"
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											if (props.close)
												props.close();
										}}
										sx={{
											paddingRight: "0px",
											marginRight: "0px",
											marginLeft: "32px"
										}}
									>
										<FontAwesomeIcon icon={faClose} transform={"shrink-1"} />
									</IconButton>
								</>
								: null
							}
						</div>

						<div className={"ptt-heading-status"}>
							<div className={`ptt-heading-presence`}>
								<UserPresenceIcon pict={null} pres={props.contact?.presence ?? 0} size={12} />
							</div>
							<Typography className={"ptt-information-head"}>
								{theSharedTranslationManager.getTranslation(iPresenceStateToString(props.contact?.presence, true))}
							</Typography>
						</div>

						{props.contact?.presence
							? <div className={"ptt-heading-presence"}>
								<Typography className={"ptt-information-text"}>
									{callOrMeetingStatusText !== "" ? callOrMeetingStatusText + " " : theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_HEADER") + " " + "(" + theSharedTranslationManager.getTranslation(iPresenceStateToString(props.contact?.presence, true)) + ")"}
								</Typography>
								{inACall}
							</div>
							: null
						}
					</div>

					{props.contact?.presence
						? <>
							<div className={"ptt-information"}>
								<Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_INFORMATION_HEADER")}</Typography>
								{getMobileAvailability()
									? <div className={"ptt-information-mobile-login"}>
										<FontAwesomeIcon
											className={"ptt-information-mobile-login-icon"}
											transform={"shrink-1"}
											icon={faMobileButton}
										/>
										<Typography className={"ptt-information-text"}>{theSharedTranslationManager.getTranslation("IDS_STATE_AVAILABLE_MOBILE")}</Typography>
									</div>
									: null
								}

								{(props.contact?.agentState !== AsnAgentStateEnum.eAGENTSTATENOAGENT)
									? <div className={"ptt-information-sign-in"}>
										<FontAwesomeIcon
											className={"ptt-information-sign-in-icon"}
											transform={"shrink-1"}
											icon={getAgentStateIcon()}
										/>
										<Typography className={"ptt-information-text ptt-information-sign-in-text"}>{getAgentStateText()}</Typography>
									</div>
									: null
								}

								{(reachability !== undefined && reachability !== AsnReachabilityEnum.eREACHABILITYNORMAL)
									? <div className={"ptt-information-sign-in"}>
										<FontAwesomeIcon
											className={"ptt-information-sign-in-icon"}
											transform={"shrink-1"}
											icon={getReachabilityTypeIcon()}
										/>
										<Typography className={"ptt-information-text ptt-information-sign-in-text"}>{getReachabilityText()}</Typography>
									</div>
									: null
								}

								{props.contact?.customNote
									? <div className={"ptt-information-time"}>
										<p>
											<FontAwesomeIcon
												className={"ptt-information-time-icon"}
												transform={"shrink-1"}
												icon={faCircleInfo}
											/>
											{props.contact.customNote}
										</p>
									</div>
									: null
								}
							</div>
							{reachability !== AsnReachabilityEnum.eREACHABILITYVACATION
								? <div className={"ptt-channels"}>
									<Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_CHANNELS_HEADER")}</Typography>
									<>
										{props.contact?.seqLineForwards !== undefined && props.contact.seqLineForwards.length > 0
											? props.contact.seqLineForwards.map((LineForward: AsnPresenceForward, index: number) => (
												<div key={index} className={"ptt-channels-lineforward"}>
													<span className={"ptt-icon"}>
														{getIcon("lineForward", "small", "plain", "ptt-channels-line-forward-svg-icon")}
													</span>
													<Typography className={"ptt-information-text"}>
													[{LineForward.u8sLineNumber.substring(15, 12)}] {theSharedTranslationManager.getTranslation("IDS_CALL_FORWARD")} [{LineForward.u8sDestination.substring(15, 12)}]
													</Typography>
												</div>
											))
											: null
										}
									</>
									<>
										{seqPhoneLines.map((phoneLine: AsnPresenceLine, index: number) => (
											getPhoneLineChannelSection(phoneLine, index)
										))}
									</>
									{callOrMeetingStatusText === theSharedTranslationManager.getTranslation("IDS_HAS_MEETING")
										? <div className={"ptt-channels-in-a-meeting"}>
											<span className={"ptt-icon"}>
												{getIcon("meetings", "small", "plain", "ptt-channels-meetings-svg-icon")}
											</span>
											<Typography className={"ptt-information-text"}>{theSharedTranslationManager.getTranslation("IDS_HAS_MEETING")}</Typography>
										</div>
										: null
									}
									{(teamsAvailable)
										? <div className={"ptt-channels-availability"}>
											<span className={"ptt-icon"}>
												{getIcon("windowsTeams", "small", "plain", getTeamsStatus() + " " + getTeamsStatusForOuterCircle())}
											</span>
											<Typography className={"ptt-information-text ptt-channels-availability-text"}>
												{teamsStatusText}
											</Typography>
										</div>
										: null}
								</div>
								: null
							}
							<div className={"ptt-appointments"}>
								{validAppointments?.map((appointment, index) =>
									<div key={"pt_" + index}>
										{(index === 0)
											? <Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_HEADER")}</Typography>
											: null
										}
										<div className={"ptt-appointments-info"}>
											{(getCurrentAppointmentTime(index) !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_NEXT24HRS"))
												? <FontAwesomeIcon
													className={getCalendarStatus(appointment)}
													transform={"shrink-1"}
													icon={faCalendar}
												/>
												: null
											}
											<Typography className={"ptt-information-text"}>{getCurrentAppointmentTime(index)}</Typography>
										</div>
										{getCurrentAppointmentSubject(appointment) && getCurrentAppointmentSubject(appointment) !== ""
											? <div className={"ptt-appointment-mobile-office"}>
												<FontAwesomeIcon
													className="ptt-appointment-mobile-office-icon"
													transform={"shrink-1"}
													icon={faMessage}
												/>

												<Typography className={"ptt-information-text"}>{getCurrentAppointmentSubject(appointment)}</Typography>
											</div>
											: null
										}
										{appointment.Location
											? <div className={"ptt-appointment-location-info"}>
												<FontAwesomeIcon
													className="ptt-appointment-location-icon"
													transform={"shrink-1"}
													icon={faLocationPin}
												/>
												<Typography className={"ptt-information-text"}>{appointment.Location}</Typography>
											</div>
											: null
										}
										<div className={"ptt-appointment-empty-section"}></div>
									</div>
								)}
							</div>
							{(nextValidAppointment?.stStart)
								? <div className={"ptt-next-appointment"}>
									<Typography className={"ptt-information-head"}>{theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NEXT")}</Typography>
									<div className={"ptt-next-appointment-calendar-info"}>
										{(getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_FURTHER_NEXT24HRS") && getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_NEXT24HRS"))
											? <FontAwesomeIcon
												className={getNextAppointmentCalendarStatus()}
												transform={"shrink-1"}
												icon={faCalendar}
											/>
											: null
										}
										<Typography className={"ptt-information-text"}>{getNextAppointmentTime()}</Typography>
									</div>
									{(getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_FURTHER_NEXT24HRS") && getNextAppointmentTime() !== theSharedTranslationManager.getTranslation("IDS_APPOINTMENT_NO_NEXT24HRS"))
										? <>
											{getNextAppointmentSubject() && getNextAppointmentSubject() !== ""
												? <div className={"ptt-next-appointment-preparation-info"}>
													<FontAwesomeIcon
														className="ptt-next-appointment-preparation-icon"
														transform={"shrink-1"}
														icon={faMessage}
													/>
													<Typography className={"ptt-information-text"}>{getNextAppointmentSubject()}</Typography>
												</div>
												: null
											}
											{nextValidAppointment?.Location
												? <div className={"ptt-next-appointment-location-info"}>
													<FontAwesomeIcon
														className="ptt-next-appointment-location-icon"
														transform={"shrink-1"}
														icon={faLocationPin}
													/>
													<Typography className={"ptt-information-text"}>{nextValidAppointment.Location}</Typography>
												</div>
												: null
											}
										</>
										: null
									}
								</div>
								: null
							}
						</>
						: null
					}
				</div>
			</Menu>
		</ClickAwayListener>
	);
});
